import React, { useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form } from "formik"

import curve from "../../../../static/images/pages/curve.svg"
import Message from "elements/Message"
import Hero from "layout/Hero"
import Container from "layout/Container"

import Button from "elements/Button"
import styles from "../utils/staticPages.module.scss"
import AuthForm from "components/Auth/AuthForm"

import { AppContext } from "context/AppContext"

import { authValidationSchema } from "../../Auth/utils/authValidationSchema"
import { handleAuthSubmit } from "../../Auth/services/auth"

const HomeBanner = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    {
      medgrocer: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const medgrocer = data.medgrocer.childImageSharp.fluid

  const { state, dispatch } = useContext(AppContext)

  const [message, setMessage] = useState({})
  const [loading, setLoading] = useState(false)

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      module: "sign-in",
    }

    handleAuthSubmit({ payload, state, dispatch })
  }

  return (
    <Hero
      size="fullheight"
      background={{ image: curve, position: "right center" }}
    >
      <Container fullhd={10} isCentered={true}>
        <div className="columns">
          <div className="column">
            <figure className={styles["logo"]}>
              <Img fluid={medgrocer} alt="MedGrocer" />
            </figure>
            <Formik
              initialValues={state.auth}
              validationSchema={authValidationSchema("sign-in")}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  <div className="mt-2">
                    <AuthForm
                      formFields={pageContext.formFields}
                      module="sign-in"
                      values={values}
                    />
                    {message?.content && (
                      <Message color={message?.type}>
                        {message?.content?.message || message?.content}
                      </Message>
                    )}

                    <Button
                      type="submit"
                      color="primary"
                      isLoading={loading}
                      isFullwidth
                    >
                      {module.cta || "Sign In"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className="column"></div> */}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
